import React from "react";
import Helmet from "react-helmet";
import { Row, Col } from "antd";
import { graphql, Link } from "gatsby";
import Layout from "../../layout/index.jsx";
import SEO from "../../components/SEO/SEO.jsx";
import config from "../../../data/SiteConfig.js";
import SvgKrkngSun from "../../components/svg/KrkngSun.jsx";
import SvgKrkngBkgrnd from "../../components/svg/KrkngBkgrnd.jsx";
import SvgContainer from "../../components/svg/Container.jsx";
import Kana from "../../components/svg/kana.jsx";

import SvgMapa from "../../components/svg/Mapa.jsx";
import { StaticImage } from "gatsby-plugin-image";
import Gallery from "../../components/Gallery/index.jsx";
import paperVideo from "./paper_fence_wind.mp4"
// import paperVideoM4v from "./paper_fence_wind.m4v"

const Karakung = ({ data }) => {
  return (
    <Layout>
      <SEO />

      <div className="index-container">
        <Helmet title={config.siteTitle} />
        <SvgKrkngBkgrnd z-index="-9" />
        <div className="fullwidth" style={{ padding: `1rem` }}>
          <Row>
            <Col
              span={24}
              sm={24}
              lg={12}
              style={{ display: `flex`, justifyContent: `center` }}
            >
              <SvgKrkngSun
                id="krkngsun"
                style={{ aspectRatio: `1`, padding: `2vw` }}
              />
            </Col>

            <Col
              span={24}
              sm={24}
              lg={12}
              style={{ padding: `1rem` }}
              className="karakung-page-is"
            >
              <p style={{ textAlign: `center`, marginTop: `1rem` }}>
                <Kana width="50px" />
              </p>
              <h2 style={{ textAlign: `center` }}>
                <strong>Karakung Reef</strong> is our home base.
              </h2>
              <p>It contains:</p>
              <ul>
                <li>
                  <a href="#tinyhouse">A tiny house</a>
                </li>
                <li>
                  <a href="#container">A shipping container</a>
                </li>
                <li>
                  <a href="#garden">A garden</a>
                </li>
              </ul>
              <p>
                We are powered by <a href="#solar">solar</a> and have internet
                :)
              </p>

              <p>
                The name <i>Karakung Reef</i> is a mishmash of the Leni Lenape
                word for what we now call Cobbs Creek and Reef as in Coral Reef.
                The Coral Reef is a place of interorganism symbiosis, just as
                our cities are places rich in symbiosis. Both reefs and cities
                are holobionts. 🪸 (<a href="#history">see history</a>)
              </p>
              <p>
                We are going to be using this space for our own work and for
                gardening, film screenings, workdays and workshops.
              </p>
              <p style={{ fontSize: `.8rem` }}>
                This page is under construction.
              </p>
            </Col>
          </Row>

          <StaticImage
            src="./picstrip.png"
            objectFit="cover"
            layout="fixed"
            imgStyle="object-position: center center"
            objectPosition="50% 50%"
            class="fullwidth"
            style={{ minHeight: `min(40vh, 572px)`, maxWidth: `100vw` }}
          />
        </div>
        <h2 id="container">the container</h2>
        <SvgContainer id="krkng-container" />
        <p>This is our power supply, workshop and space for storage.</p>
        <p>
          It's our laboratory for experimentation, battery recycling, assembly
          and repair.
        </p>
        <h3 id="solar">Our solar setup:</h3>
        <div className="tech-details">
          <ul>
            <li>4 400W Q-cell Panels</li>
            <li>5000W Growatt inverter</li>
            <li>20KW LifePo4 recycled battery</li>
            <li>JBD BMS</li>
          </ul>
        </div>
        <p>
          We set this up to double as a demonstration setup to be able to
          showcase a basic off grid solar PV install.
        </p>
        <p>
          The panels were bought new and mediate our container's interface with
          the sun. 🌞 They're mounted using DIY unistrut mounts. The rear panels
          are mounted using specially made solar mounting brackets for shipping
          containers. The front 2 panels are mounted with magnets rated to match
          standard ballast mounted solar racking. They've already withstood the
          tests of numerous high wind events, but happen to generate unexpected
          remote anxiety during such events (so we may change this soon).
        </p>
        <p>
          Our Growatt inverter takes the power generated by the PV modules and
          charges our battery. It also converts the DC battery power into
          household AC to use in the container and shipping container for
          lights, computers and tools.
        </p>
        <p>
          Our battery is a formidable beast. Sourced at our favorite battery
          recycling warehouse in Bensalem, we bought this at an auction. It
          holds about 20 kilowatt hours of power and was formerly used in some
          sort of industrial application (maybe a crane or forklift). Getting it
          running included several steps. The most difficult was to move it.
          Battery Hookup kindly plopped it down on a pickup for us with their
          forklift. But moving it into the container involved a small crew of
          locally sourced muscle, plenty of cursing and a bit of common sense
          engineering to minimize the pushes and lifts.
        </p>
        <p>
          We added our own <a href="https://en.wikipedia.org/wiki/Battery_management_system">BMS</a> and then later had to surgically bypass a bad cell from the bottom of
          the metal box. Other battery adventures followed, but those are out of
          scope of this page.
        </p>
        <p>
          Thanks to Steph, James, Petri, Isaiah especially for helping with the
          wiring and moving!
        </p>
        <Gallery pics={data.solargallery.data.image.localFiles} />
        <h2>tiny house</h2>
          <p>This is where we have our meetings, occasional workshops and work out of.</p>
          <StaticImage src="./sand_batt_wkshop.jpg" alt="Breaking down Ohm's law at the sand battery workshop." />
          <Gallery
          pics={data.tinyhouse.data.image.localFiles}
        />
        <h2 id="garden">Garden</h2>
        <p>
          Part of this project is an aspiring community garden. We own just the
          crowded bit of land in the middle, but are surrounded by vacant city
          owned lots.
        </p>
        <p>
          Please <Link to="/contact">reach out to us</Link> if you live nearby and are interested in this
          project. We'll put you on our list of people to contact when anything
          moves forward.
        </p>
        <p>
          We have a vision of a participatory community garden run by the
          neighborhood. You can help make this happen!
        </p>
        <p>For now as we are barely maintaining the few beds that line our fence</p>
        <p>In the fall of 2022, before we aquired our structures, we planted a series of raised beds to grow flowers for the Black Mama's bail out day.</p>
        <Gallery
          pics={data.gardening.data.image.localFiles}
          title="Gardening at the Reef"
        />
        <br />
        <div id="life">
          <h2>Life at the Reef</h2>
          <p>Occasional events at the Reef fill our sleepy lot with life. One of our first events was a collaboration with our dear comrades from the <a href="http://peoplespaperco-op.weebly.com/">People's Paper Coop.</a> We set up a small demonstration solar powered blender to pulp legal documents from which we made paper for mothers day cards. Those cards were given to the mothers bailed out for the <a href="https://www.phillybailout.org/">Black Mama's bail out day</a>. </p>
          <Gallery
            pics={data.ppcws.data.image.localFiles}
            title="People's Paper Coop event"
          />
          <br />
          <video muted autoplay="autoplay" loop controlslist="nofullscreen nodownload noremoteplayback" class="fullwidth">
            <source src={paperVideo} type="video/mp4" />
            Download the <a href="./paper_fence_wind.mp4">video</a>.
          </video>
          <figcaption>Paper hanging to dry in the sun.</figcaption>
          <br/>
          <figure style={{ width: `100%` }}>
            <StaticImage src="./PPC_group.jpeg" style={{ width: `100%` }} />
            <figcaption>
              Papermaking Workshop in support of Black Mother's Bailout Day
            </figcaption>
          </figure>
          <br/>
        </div>
        <Row>
          <Col>
            <h2 id="history">History</h2>
            <StaticImage src="./cobbs_creek_1946.jpg" className="fullwidth" />
            <p>
              As we decided to come up with a name we also dug into the history
              of the land. Before European invasion and colonization, this was
              of course Leni Lenape land.
            </p>
            <p>A cotton mill was later built on this location:</p>
            <StaticImage src="./angora_mills_sm.png" className="fullwidth" />
            <br/>
            <p>Since moving to Cedarhurst, we've heard the land where we are based contained several homes, demolished in the 90s due to underwater streams eroding the foundations. </p>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allAirtable(
      limit: 50
      sort: { fields: data___date, order: DESC }
      filter: { data: {}, table: { eq: "images" } }
    ) {
      edges {
        node {
          id
          data {
            image {
              localFiles {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    ppcws: airtable(
      table: { eq: "images" }
      data: { slug: { eq: "PPC_workshop_2" } }
    ) {
      data {
        slug
        title
        status
        date
        author
        image {
          id
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    solargallery: airtable(
      table: { eq: "images" }
      data: { slug: { eq: "battery_gallery" } }
    ) {
      data {
        slug
        title
        status
        date
        author
        image {
          id
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    tinyhouse: airtable(
      table: { eq: "images" }
      data: { slug: { eq: "tiny_house" } }
    ) {
      data {
        slug
        title
        status
        date
        author
        image {
          id
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    gardening: airtable(
      table: { eq: "images" }
      data: { slug: { eq: "gardening" } }
    ) {
      data {
        slug
        title
        status
        date
        author
        image {
          id
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default Karakung;
