import * as React from "react";
const Kana = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35.971 35.066"
    {...props}
  >
    <path
      d="M147.48 121.021c-1.55-1.879-.331-4.385-.791-6.536-.093-9.132-.161-18.266.04-27.397 1.6-1.794 4.13-.6 6.16-1.04 9.605-.119 19.21-.053 28.815-.082 1.611 1.892.423 4.445.708 6.645-.173 9.035-.016 18.078-.445 27.108-1.427 1.743-3.822.827-5.73.936-9.367.245-18.745.403-28.118.373l-.32-.003zm18.829-2.452c3.768-.065 7.549.15 11.305-.245 1.578-1.79-.796-3.619-1.272-5.348-2.054-4.544-4.453-8.924-6.653-13.398-1.185-2.325-2.406-4.634-3.754-6.869-2.334-1.019-2.88 2.331-3.948 3.654-1.75 3.29-3.766 6.426-5.384 9.785-1.818 3.437-3.643 6.887-5.545 10.268-.834 2.3 1.798 2.587 3.385 2.187 3.956.056 7.91-.072 11.866-.034m-16.267-4.945c3.32-5.264 5.58-11.12 9.022-16.315 1.396-2.36 2.771-4.75 3.756-7.317-.335-2.618-3.607-1.79-5.433-1.685-2.533.136-5.08.1-7.601.429-1.702 1.568-.771 4.013-.853 6.006-.246 6.114-.24 12.25-.048 18.368 0 .914.571 1.388 1.157.514m30.017-12.287c-.03-4.062.028-8.127-.174-12.186-1.534-1.773-4.01-.612-5.982-.969-2.125.36-4.636-.751-6.436.863-.518 2.308 1.784 3.792 2.32 5.839a727 727 0 0 0 7.567 15.132c.519.934 1.114 3.975 2.49 2.4.574-1.739-.008-3.576.199-5.37q.027-2.854.016-5.71"
      style={{
        fill: "#000",
        strokeWidth: 0.264583,
      }}
      transform="translate(-146.598 -85.966)"
    />
  </svg>
);
export default Kana;
